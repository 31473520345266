import React from "react";
import { GUIDES_LINKS } from "components/docs/navigation/links";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import HeaderBar from "components/docs/layout/HeaderBar";
import Section, {
  UnifiedAPISection,
  WritingDataSection,
  PlatformAndAccountMetadataSection,
  SupplementalDataSection,
  MergeLinkSection,
} from "components/docs/sections/GuidesSection";

const GuidesPage = () => (
  <StaticPageContainer style={{ paddingBottom: 0 }}>
    <DocsHelmet
      title="Merge Guides"
      description="Learn how to use Merge's features via our guided introductions."
    />

    <HeaderBar
      title="Guides"
      subtitle="Learn about interacting with Merge's Unified API and third-party platforms"
      className={"pb-12"}
    />

    <UnifiedAPISection showDivider={false} />

    <MergeLinkSection />

    <WritingDataSection />

    <PlatformAndAccountMetadataSection />

    <SupplementalDataSection />

    <Section
      title="Testing"
      subtitle="Learn about best practices for testing the Merge Unified API"
      tiles={[
        {
          to: "/guides/testing-via-postman",
          title: "Testing Merge’s Unified API via Postman",
          subtitle: "Best practices for testing Merge’s Unified API via Postman",
        },
      ]}
    />

    <Section
      title="Specifications"
      subtitle="Learn about common conventions used in the Merge Unified API"
      tiles={[GUIDES_LINKS.SCHEMA_PROPERTIES]}
    />
  </StaticPageContainer>
);

export default GuidesPage;
